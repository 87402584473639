app-data-table {
  display: block;
  // margin: pxToRem(160) pxToRem(48) pxToRem(56) pxToRem(80);
  padding: 0 !important;

  // table
  table {
    position: relative;
    z-index: 0;
    border-collapse: separate !important;
    border-spacing: 0 1rem !important;
    background: transparent !important;
    box-shadow: none !important;
    height: fit-content;
    padding-inline-end: pxToRem(80) !important;
    padding-inline-start: pxToRem(48) !important;

    @media only screen and (max-width: 768px) {
      padding-inline-end: pxToRem(20) !important;
      padding-inline-start: pxToRem(20) !important;
    }

    .mat-mdc-header-cell,
    .mdc-data-table__cell {
      text-align: center !important;
      color: $accent-color;
      @extend .label-medium;
    }

    tr.mat-mdc-header-row {
      background-color: #ffffff;
      position: sticky;
      position: -webkit-sticky; /* Safari */
      z-index: 100;
      top: 0;
    }

    th.mat-mdc-header-cell {
      border-top: 1px solid $border !important;
      border-bottom: 1px solid $border !important;
      background-color: #ffffff;
      text-align: center;
      padding: pxToRem(25) 0;
      @media only screen and (max-width: 768px) {
        padding: 0;
      }
      .mat-sort-header-arrow {
        display: none !important;
      }
    }

    td.mat-mdc-cell:first-child,
    th.mat-mdc-header-cell:first-child {
      border-right: 1px solid $border;
    }

    td.mat-mdc-cell:last-child,
    th.mat-mdc-header-cell:last-child {
      border-left: 1px solid $border;
    }

    tr.mat-mdc-row {
      background-color: $background-light;
      position: relative;

      &:nth-child(even) {
        background-color: #ffffff;
      }

      &:hover {
        background-color: $scrollbar-color !important;
      }
      &.customRowActionFlag {
        cursor: pointer;
      }
    }

    td.mat-mdc-cell {
      text-align: center;
      border-top: 1px solid $border !important;
      border-bottom: 1px solid $border !important;
      min-width: 15rem;
      // width: 18.75rem;
      // min-width: 18.75rem;
      // max-width: 18.75rem;
      padding: 0.05rem pxToRem(30);

      .data-text {
        padding: pxToRem(20) 0;
        @media only screen and (max-width: 768px) {
          padding: 0;
        }
        direction: ltr;
        white-space: nowrap;

        // width: 20rem;
        &.tooltip {
          // width: 12rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: auto;
          // direction: rtl !important;
        }

        &.bank-status[data-value="مفعل"]::after {
          content: "مفعل";
          display: block;
          border: 0.5px solid $secondary-color;
          background: rgba(7, 178, 126, 0.1);
          color: $secondary-color;
          @extend .isDisabled-rounded;
        }

        &.bank-status[data-value="غير مفعل"]::after {
          content: "غير مفعل";
          display: block;
          border: 0.5px solid $alert-color;
          background: rgba(186, 57, 57, 0.2);
          color: $alert-color;
          @extend .isDisabled-rounded;
        }

        &.bank-status {
          span {
            display: none;
          }
        }
      }
      .isDisabled-rounded {
        border-radius: pxToRem(8) !important;
        padding: pxToRem(3) pxToRem(7);
        @extend .body-medium-2;
        white-space: nowrap;
        width: 5rem;
        margin: auto;
      }
      .isDisabled {
        span {
          display: none;
        }

        &[data-value="1"] {
          &::after {
            content: "غير مفعل";
            display: block;
            border: 0.5px solid $alert-color;
            background: rgba(186, 57, 57, 0.2);
            color: $alert-color;
            @extend .isDisabled-rounded;
          }
        }

        &[data-value="0"] {
          .span {
            display: none;
          }
          &::after {
            content: "مفعل";
            display: block;
            border: 0.5px solid $secondary-color;
            background: rgba(7, 178, 126, 0.1);
            color: $secondary-color;
            @extend .isDisabled-rounded;
          }
        }
      }

      .mat-mdc-select {
        // width: 20rem;

        display: flex;
        justify-content: center;
        &.mat-mdc-select-disabled {
          .mat-mdc-select-value-text,
          .mat-mdc-select-arrow {
            color: $icons-color !important;
            border-color: $icons-color !important;
            pointer-events: none;
          }
        }

        &[aria-expanded="true"] {
          .mat-mdc-select-arrow {
            transform: rotate(-135deg) !important;
            -webkit-transform: rotate(-135deg);
            border-color: $primary-color !important;
          }
        }

        .mat-mdc-select-value-text {
          color: $primary-color !important;
        }

        .mat-mdc-select-trigger {
          width: auto !important;
          gap: 0.5rem;
          position: unset;
        }

        .mat-mdc-select-arrow {
          position: unset;
          transform: rotate(45deg) !important;
          -webkit-transform: rotate(45deg);
          border: solid $secondary-color;
          border-width: 0 2px 2px 0;
          padding: 2.5px;
          width: unset !important;
          height: unset !important;

          svg {
            display: none !important;
          }
        }
      }

      .inquiry_number,
      .question_number {
        @extend .title-medium-3;
        @extend .d-flex-center;
        color: $secondary-color;
        background-color: rgba(7, 178, 126, 0.1);
        padding: 0 pxToRem(8);
        width: 30%;
        padding: 0 2rem;
        margin: auto;
        border-radius: pxToRem(4);
      }

      .inquiry_name {
        min-width: 30rem !important;
      }

      .inquiry_status {
        @extend .inquiry_status;
      }

      .question_name {
        min-width: 25rem !important;
        width: 12rem !important;
      }
      // .iconAction {
      //   cursor: pointer;
      //   font-size: 32px;
      //   width: 32px;
      //   height: 32px;
      //   font-weight: 300;
      // }
    }

    .btn-show {
      // min-width: 12rem;
      margin: 0 !important;

      &.customHover {
        &:hover {
          color: $primary-color !important;
        }
      }

      &.mat-mdc-button .mdc-button__label,
      &.mat-mdc-unelevated-button .mdc-button__label,
      &.mat-mdc-raised-button .mdc-button__label,
      &.mat-mdc-outlined-button .mdc-button__label {
        max-width: 12rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.show-data {
        @extend .title-large-3;
        gap: pxToRem(5);
        &::after {
          font-family: "Material Symbols Outlined";
          content: "\e941";
          position: relative;
          display: block;
          font-size: 24px;
          transform: rotateZ(180deg);
          font-weight: 300;
        }
      }

      &.delete-admin {
        cursor: pointer;
        &.condition {
          display: none !important;
        }
        &::after {
          font-family: "Material Symbols Outlined";
          content: "delete";
          position: relative;
          display: block;
          font-size: 32px;
          width: 32px;
          height: 32px;
          font-weight: 300;
          color: $alert-color;
        }
      }

      &.block-user {
        cursor: pointer;
        &.condition {
          &::after {
            font-family: "Material Symbols Outlined";
            content: "lock_open_right";
            position: relative;
            display: block;
            font-size: 32px;
            width: 32px;
            height: 32px;
            font-weight: 300;
            color: $secondary-color;
          }
        }
        &::after {
          font-family: "Material Symbols Outlined";
          content: "lock";
          position: relative;
          display: block;
          font-size: 32px;
          width: 32px;
          height: 32px;
          font-weight: 300;
          color: $alert-color;
        }
      }
    }
  }

  // divider
  .mat-divider {
    display: none;
  }

  // content header
  .content-page-header {
    width: 100% !important;
    margin-right: unset !important;
    margin-bottom: pxToRem(40) !important;
    margin-left: unset !important;
    padding-inline-start: pxToRem(48) !important;
    padding-inline-end: pxToRem(80) !important;

    @media only screen and (max-width: 768px) {
      padding: pxToRem(20) 0;
      margin-bottom: pxToRem(20) !important;
      padding-inline-end: pxToRem(20) !important;
      padding-inline-start: pxToRem(20) !important;
      // position: sticky;
      // top: 0;
      // background: $white-color;
      // z-index: 5;
    }
    .contents {
      align-items: flex-start !important;
      padding: unset !important;
      gap: 8rem;
      // align-items: flex-start !important;
      // justify-content: flex-start !important;
      @media only screen and (max-width: 768px) {
        flex-direction: column !important;
        position: relative;
        gap: 1rem !important;
        align-items: flex-start !important;
        .header-title h2 {
          white-space: normal !important;
        }
      }
      .header-title h2 {
        color: $primary-color !important;
        font-size: 1.75rem !important;
        line-height: 3.5rem !important;
        white-space: nowrap;
        line-height: unset !important;
      }
      .k-ng-filter {
        mat-form-field {
          .mat-mdc-text-field-wrapper {
            height: 3rem;
          }
        }
      }
      .k-ng-search {
        width: 28%;
        .search-btn {
          position: absolute;
          left: -8rem;
          bottom: -0.2rem;
          background-color: $primary-color !important;
          color: white !important;
        }
        mat-form-field {
          width: 100%;
          .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
            gap: 0.4rem;
          }

          &.ng-touch.ng-invalid.ng-dirty.mat-form-field-invalid.ng-touched
            .mdc-notched-outline.mdc-notched-outline--no-label.mdc-notched-outline--notched {
            border-color: $alert-color !important;
            border-radius: pxToRem(8) !important;
            border-width: 1px !important;
          }

          input::placeholder {
            color: $icons-color;
            @extend .body-medium;
            font-size: 1.1rem !important;
            // line-height: unset !important;
          }
        }

        mat-icon {
          font-family: "Material Symbols Outlined";
          color: $icons-color;
          font-size: 20px;
          height: 20px;
          width: 20px;
          &.search-icon {
            cursor: pointer;
          }
          &.close-icon {
            color: $secondary-color !important;
            cursor: pointer;
          }
        }

        @media only screen and (max-width: 768px) {
          width: 75%;
        }
      }
      .header-actions {
        // width: 60%;
        gap: pxToRem(12) !important;
        justify-content: space-between;
        align-items: flex-start;

        .btn {
          height: 3rem !important;
          width: max-content;
          padding: 0 1rem !important;
        }
        .btnActions-resp {
          .mat-mdc-button mat-icon {
            margin: 0 !important;
            color: $primary-color;
            font-size: 1.5rem;
            height: 1.5rem;
            width: 1.5rem;
          }
          .mdc-button {
            min-width: unset !important;
            padding: 0 !important;
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        // .btn-action {
        //   // width: 30%;
        //   @extend .d-flex;
        //   gap: pxToRem(16);
        // }
      }
    }
  }

  // content body
  .content-page-body {
    width: 100% !important;
    @media only screen and (max-width: 768px) {
      margin-block-end: 5rem !important;
    }
    .empty-state {
      @extend .d-flex-center;
      flex-direction: column;
      padding-top: pxToRem(100);
      flex-direction: column;
      gap: pxToRem(48);
      color: $text-color;

      figure {
        img {
          display: block;
          width: 100%;
        }
      }

      .message {
        @extend .label-medium-2;
        color: $icons-color;
      }
    }

    .table-loading-spinner {
      padding-top: pxToRem(120) !important;
      @extend .d-flex-center;
    }

    .table-border {
      min-height: 60vh;
      max-height: 70vh !important;
      margin-inline-end: pxToRem(80);

      &.scroll_width {
        margin-inline-end: 0 !important;
        max-width: 100%;
        // padding-inline-end: pxToRem(80) !important;
        // padding-inline-start: pxToRem(48) !important;
        padding: 0 !important;
        // overflow: hidden;
        overflow: auto;
        &:hover {
          overflow: auto;
          // cursor: grabbing;
          // cursor: -webkit-grabbing;
          -webkit-overflow-scrolling: touch;
        }
        &::-webkit-scrollbar {
          width: 0.8rem !important;
          height: 0.8rem !important;
        }
        @media only screen and (max-width: 1280px) {
          overflow: auto;
        }
      }

      @media only screen and (min-width: 769px) and (max-height: 750px) {
        max-height: 65vh !important;
        padding-inline-end: 1rem;
      }

      // @media only screen and (min-width: 769px) and (max-height: 650px) {
      //   max-height: 55vh !important;
      // }

      @media only screen and (max-width: 768px) {
        min-height: unset !important;
        max-height: unset !important;
        height: auto !important;
        // margin-block-end: 35% !important;
      }
    }
  }

  // .tooltip {
  //   // position: relative;
  //   display: inline-block;
  //   max-width: 5rem;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   direction: rtl !important;
  // }

  // .tooltip .tooltip:hover .tooltiptext {
  //   visibility: visible;
  // }

  // pagination
  app-pagination {
    // bottom: 2.5rem !important;
    // padding: pxToRem(34) 0 0 pxToRem(84);
    // width: 100%;
    @media only screen and (max-width: 768px) {
      position: relative !important;
      width: 100%;
    }
    .pagination {
      // width: 100%;
      position: relative;
      bottom: 0rem;
      background: $white-color;
      padding: pxToRem(11) 0 0 pxToRem(84);
      // margin-inline-start: 3rem !important;
      @media only screen and (max-width: 1400px) {
        // bottom: 1rem !important;
      }
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        padding: pxToRem(20) 0;
        width: 100%;
        gap: 0.5rem !important;
        bottom: 0rem !important;
      }
      &-action {
        margin-inline-start: 3rem !important;
        @media only screen and (max-width: 768px) {
          width: 100%;
          justify-content: center !important;
          padding: 0 1rem;
          margin-inline-start: 0rem !important;
        }
      }
      &__btn {
        @extend .body-medium;
        color: $accent-color;
        background: $white-color !important;
        padding: 0.5rem;

        &:hover {
          background: $scrollbar-color !important;
          border-radius: pxToRem(8);
          color: $primary-color !important;
        }
      }

      &__toggle-btns {
        background-color: transparent !important;
        border: none !important;
        color: $white-color;

        &__dots {
          color: $accent-color;
          pointer-events: none;
          @extend .body-small;
        }

        &__btn {
          border-radius: pxToRem(8);
          height: pxToRem(32);
          width: pxToRem(32);
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 0.5rem;
          @extend .body-small;
          color: $accent-color;

          &:hover {
            background-color: white !important;
            --mat-standard-button-toggle-hover-state-layer-opacity: 0 !important;
            --mat-standard-button-toggle-focus-state-layer-opacity: 0 !important;
          }

          &__active {
            background-color: $secondary-color !important;
            color: $white-color !important;
            padding: 0 !important;

            &:hover {
              background-color: $secondary-color !important;
            }
          }
        }

        .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
          padding: 0 !important;
        }
      }

      &-count {
        color: $icons-color;
        word-spacing: 0.1rem;
        font-size: pxToRem(16);
        line-height: pxToRem(32);
        font-weight: 400;
        @media only screen and (max-width: 768px) {
          display: none !important;
        }
      }
    }

    .disabled {
      pointer-events: none;
      color: $icons-color !important;
    }
  }
}
// custom pane
::ng-deep {
  .tooltiptext {
    background-color: $snackbar-bg !important;
    color: $white-color !important;
    text-align: center;
    border-radius: pxToRem(8);
    padding: pxToRem(8);
    width: 100%;
    // max-width: max-content !important;
    @extend .body-medium;
    &.tooltipSearch {
      top: -1rem;
    }
  }

  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $snackbar-bg transparent transparent transparent;
  }

  .mat-mdc-tooltip .mdc-tooltip__surface {
    @extend .body-medium;
    background-color: $snackbar-bg !important;
    color: $white-color !important;
    padding: 0 !important;
    text-align: center !important;
    max-width: pxToRem(500);
    max-height: pxToRem(300);
    overflow-y: auto;
    scrollbar-color: $white-color transparent !important;
    padding: 0 1rem !important;
    &::-webkit-scrollbar {
      width: 0.2rem;
    }
    &::-webkit-scrollbar-track {
      background: $icons-color !important;
    }

    &::-webkit-scrollbar-thumb {
      background: $white-color !important;
    }
  }

  .cdk-overlay-pane {
    &.selectCustomClass {
      width: auto !important;
      &.selectLevelClass,
      &.selectFilterClass {
        width: 100% !important;
      }
    }

    .selectCustomClass {
      border-radius: pxToRem(8) !important;
      background: $background-light !important;
      box-shadow: $box-shadow !important;
      padding: 0 !important;

      .mat-mdc-option,
      mat-mdc-option {
        border-bottom: 0.25px solid $lines-color;

        .mdc-list-item__primary-text {
          white-space: nowrap;
        }

        .mat-pseudo-checkbox-minimal,
        mat-pseudo-checkbox {
          display: none !important;
        }

        &:hover {
          background-color: transparent !important;
        }

        &:last-child,
        &:first-child {
          border: none !important;
        }

        &:first-child {
          display: none !important;
        }

        &.canCloseFlag,
        &.canActionFlag {
          pointer-events: none;
          opacity: 0.5;
        }
      }

      .mat-mdc-option,
      mat-mdc-option {
        @extend .body-small;
      }

      .mat-mdc-option.mat-mdc-active,
      .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple),
      .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple):not(.mat-mdc-option-disabled),
      .mdc-list-item--selected.mdc-list-item--disabled {
        background-color: transparent !important;
      }

      .mat-mdc-option .mat-mdc-option-ripple {
        display: none;
      }

      .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
        color: unset;
      }

      &.selectLevelClass {
        .mat-mdc-option,
        mat-mdc-option {
          border-bottom: 0.25px solid $lines-color !important;

          .mdc-list-item__primary-text {
            color: $text-color !important;
            @extend .body-small;
            line-height: unset !important;
            background-color: transparent !important;
          }

          margin: 0 1rem;

          &:first-child {
            display: flex !important;
            border-bottom: 0.25px solid $lines-color !important;
          }

          &:last-child {
            border: none !important;
          }
        }
        .mat-mdc-option.mat-mdc-active,
        .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple),
        .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple):not(.mat-mdc-option-disabled) {
          margin: 0 !important;
          padding: 0 28px !important;
          background-color: $shapes-color !important;
        }
      }
    }

    .matMenuClass {
      .mat-mdc-menu-content {
        padding: 0 !important;
      }
      .mat-mdc-menu-item {
        padding: 0 pxToRem(16) !important;
        border: none !important;
        border-bottom: 0.25px solid $lines-color !important;
        display: flex;
        justify-content: flex-start !important;
        width: 100% !important;
        background-color: transparent !important;
        border-radius: 0 !important;
        direction: rtl;
        mat-icon {
          order: 2;
          color: $secondary-color !important;
        }
        &:last-child {
          border: none;
        }
      }
      .mat-mdc-menu-item:not([disabled]):hover {
        background-color: transparent !important;
      }
      .mat-mdc-menu-ripple {
        display: none;
      }
      .mat-mdc-menu-item-text {
        @extend .body-small;
        color: $accent-color !important;
      }
    }
  }

  .mat-mdc-form-field-icon-suffix .mat-icon {
    padding: 0 !important;
  }
}

.filter-page {
  position: relative;
  &_main {
    grid-template-columns: 4fr 1fr;
    margin: 0 auto;
    width: 100%;
    padding: 0.5rem 3rem;
    // position: absolute;
    &_header {
      display: flex;
      align-items: center;
      // margin-bottom: 1rem;

      h4 {
        font-size: 1.5rem;
        font-weight: bold;
        color: $accent-color;
        margin-left: 1rem;
      }

      &_badges {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 1.5rem;
        .badge {
          height: 3rem;
          width: fit-content;
          border-radius: 1.5rem;
          box-shadow: 0px 2px 6px #0000001a;
          min-width: 14rem;
          display: grid;
          place-items: center;
          font-size: 1.25rem;
          padding: 0 0.5rem;
          position: relative;
          color: $accent-color;
          padding: 0 2rem;
          margin: 0 1rem;
          background: white;

          .close-btn {
            position: absolute !important;
            top: -0.5rem;
            left: -0.5rem;
            font-size: 1rem;
            height: 1.25rem;
            width: 1.25rem;
            background-color: $accent-color;

            border-radius: 50%;
            color: white;
            cursor: pointer;
            @extend .d-flex-center;

            &:hover {
              background-color: $secondary-color;
            }
          }
        }
      }
    }
    &_content {
      width: 73%;
    }
  }

  &.filterBody {
    app-data-table .content-page-header {
      margin-bottom: 0.5rem !important;
    }
  }
}

.requested-plates {
  app-data-table {
    .content-page-header .contents {
      align-items: flex-start !important;

      .header-actions {
        flex-wrap: wrap;
        justify-content: flex-end;
        @media only screen and (max-width: 768px) {
          justify-content: flex-start;
        }
      }

      .k-ng-filter {
        order: 4;
        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
    .content-page-body .table-border {
      // min-height: 50vh;
      // max-height: 52vh !important;
      @media only screen and (min-width: 768px) and (max-height: 666px) {
        min-height: 50vh;
        max-height: 61vh !important;
      }

      // @media only screen and (max-height: 660px) {
      //   max-height: 57vh !important;
      // }
    }

    .content-page-body .empty-state {
      padding-top: 3.5rem;
      gap: 2rem;
    }
  }
}

.offers-table {
  @media only screen and (max-width: 768px) {
    overflow-x: auto;
  }
  app-data-table {
    .table-border {
      &.scroll_width {
        overflow: visible;
        min-height: fit-content !important;
        max-height: fit-content !important;
        @media only screen and (max-width: 768px) {
          overflow-x: auto;
        }
        &:hover {
          overflow: visible;
          @media only screen and (max-width: 768px) {
            overflow-x: auto;
          }
        }
      }
    }
    .mat-paginator-sticky[_ngcontent-ng-c1607844051] {
      position: relative !important;
    }
  }
}
