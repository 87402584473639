$primary-color: #2e2e42;
$accent-color: #051d30;
$secondary-color: #8ab240;
$alert-color: #ba3939;
$white-color: #fff;
$box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.42);
$admin-background: #f6faf9;
$border: #ccc;
$background-light: #f9f9f9;
$overlay: rgba(15, 15, 16, 0.5);
$snackbar-bg: #323232;
$text-color: #07213c;
$icons-color: #77797b;
$shapes-color: #ddeaf3;
$lines-color: #c0c0c0;
$scrollbar-color: rgba(7, 178, 126, 0.04);
$secondary-color-opacity-5: rgba(46, 46, 66, 0.5);
$scroll-color: #e5e7e8;
$chat-color: #4776a7;
$chat-color-opacity-8: #4776a7cc;
$border-color: #99acc4;
$font-family-en: "Roboto", sans-serif;
$font-family-ar: "Tajawal", sans-serif;
:root {
  --secondary-color: #8ab240;
  --alert-color: #ba3939;
  --accent-color: #051d30;
}
