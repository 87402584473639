::ng-deep {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mat-mdc-form-field-infix {
    min-height: pxToRem(48) !important;
    height: pxToRem(48) !important;
  }
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding: pxToRem(6) 0 !important;
    display: flex;
    align-items: center;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-radius: pxToRem(8) !important;
    border-width: 1px !important;
    border-color: $icons-color !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
    border-radius: pxToRem(8) !important;
    border-width: 1px !important;
    border-color: $secondary-color !important;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
  .mdc-text-field--focused .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    caret-color: $secondary-color !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-text-field--focused
    .mdc-notched-outline
    .mdc-notched-outline__leading {
    border-color: $secondary-color !important;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    height: 100%;
  }
  .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input {
    caret-color: $alert-color !important;
  }
  .mdc-text-field--outlined .mdc-notched-outline,
  .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
  .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading {
    border-color: $alert-color !important;
    border-radius: pxToRem(8) !important;
    border-width: 1px !important;
  }

  .mdc-notched-outline__leading {
    display: none;
  }
  .mdc-notched-outline__trailing {
    border-right: 1px solid !important;
    border-radius: pxToRem(8) !important;
  }

  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: #0000000f !important;
  }

  .mat-mdc-form-field-error {
    margin-top: pxToRem(8) !important;
    color: $alert-color !important;
  }

  .mat-mdc-form-field-bottom-align::before {
    height: auto !important;
  }

  .mat-mdc-button .mat-mdc-button-persistent-ripple::before {
    background-color: transparent !important;
  }

  .mat-mdc-button .mat-mdc-button-ripple,
  .mat-mdc-unelevated-button .mat-mdc-button-ripple,
  .mat-mdc-raised-button .mat-mdc-button-ripple,
  .mat-mdc-outlined-button .mat-mdc-button-ripple,
  .mat-mdc-button .mat-mdc-button-touch-target {
    display: none !important;
    visibility: hidden !important;
  }
  .mat-mdc-dialog-container .mdc-dialog__surface {
    direction: rtl;
    border-radius: 1rem !important;
    overflow: hidden;
    @media only screen and (max-width: 768px) {
      height: auto !important;
    }
  }

  .mat-mdc-button:disabled {
    opacity: 0.5;
    // background-color: rgba(0, 0, 0, .12) !important;
    color: rgba(0, 0, 0, 0.26) !important;
  }

  .mat-mdc-radio-group {
    display: flex;
    flex-direction: column;
  }

  .mat-mdc-radio-button .mdc-radio {
    padding: 0.8rem 0.1rem !important;
  }

  .mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: $secondary-color !important;
  }

  .mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    border-color: $secondary-color !important;
  }

  .mat-mdc-simple-snack-bar {
    align-items: center;
  }
  .mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    min-width: auto !important;
  }
}

[dir="rtl"] .mat-calendar-previous-button,
[dir="rtl"] .mat-calendar-next-button {
  transform: rotate(0deg) !important;
}
.mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0.8rem;
}
